.courses {
    width: 100%;
    background-color: #ffffff;

    // border-bottom: 4px dotted #861be3;

    .__in {

        width: 100%;
        max-width: 1100px;
        padding: 44px 14px;
        box-sizing: border-box;

        margin: 0 auto;
        position: relative;

        display: flex;
        align-items: center;
        flex-wrap: wrap;

        justify-content: space-between;

        .title {
            color: #181818;
            font-size: 56px;
            @media screen and (max-width: 500px) {
                & {
                    font-size: 46px;
                }
            }
            line-height: 90%;
            font-weight: 800;
            max-width: 770px;

            margin-bottom: 28px;
        }

        .sub_title {
            min-width: 300px;
            color: #181818;
            font-size: 24px;
            line-height: 1.2;
            font-weight: 400;
        }

        .list {
            margin-top: 34px;

            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            margin-bottom: 34px;

            @media screen and (max-width: 959px) {
                & {
                    grid-template-columns: 1fr 1fr;
                }
            }

            @media screen and (max-width: 500px) {
                & {
                    grid-template-columns: 1fr;
                }
            }

            .card {

                cursor: pointer;

                &:active {
                    transform: scale(0.94);
                    transition: transform 0.2s;
                }

                flex: 1;

                background: #dde6e7;
                transition: 0.1s;

                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 467px;
                border-radius: 4px;
                overflow: hidden;

                &:last-child {
                    margin-right: 0;
                }

                .icon {
                    position: absolute;
                    z-index: 1;
                    bottom: 70px;
                    right: 30px;
                    transform: rotate(-45deg);


                }

                &:hover .icon {
                    transform: scale(1.5) rotate(-40deg);
                    transition: 0.4s;
                }


                .content {

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;
                    height: 100%;
                    padding: 32px 30px;
                    color: #033932;
                    position: relative;
                    z-index: 3;
                    transition: 0.1s;

                    box-sizing: border-box;

                    .top {

                        .name {
                            color: #033932;
                            margin-bottom: 4px;
                            letter-spacing: 3px;

                            font-size: 32px;
                            line-height: 34px;
                            font-weight: 600;
                            width: min-content;
                        }

                        .info {
                            color: #033932;
                            margin-bottom: 4px;

                            font-size: 18px;
                            font-weight: 500;
                        }

                        .period {
                            font-size: 18px;
                            font-weight: 500;
                            margin: 14px 0;

                            span {
                                padding: 2px 8px;
                                border-radius: 4px;
                                background-color: white;
                            }
                        }

                        .text {
                            max-width: 300px;
                            color: #181818;
                            line-height: 1.2;

                            font-size: 20px;
                            font-weight: 450;
                        }
                    }

                    .bottom {

                        .price {
                            font-size: 30px;
                            line-height: 0.9;
                        }

                    }

                }

                &.p1 {
                    background: #F5E6FE;

                    .text span {
                        background: #d9bfe7;
                    }
                }

                &.p2 {
                    background: #FFF9DD;

                    .text span {
                        background: #e9e3c7;
                    }

                }

                &.p3 {
                    // background: #F5E6FE;
                    background: #fdcbd6;

                    .text span {
                        background: #ecb9c4;
                    }
                }

                &.p4 {
                    background: #FFF9DD;
                    // border-color: #d69aa7;

                    .text span {
                        background: rgb(230, 221, 179);
                    }

                }

            }
        }

    }

}