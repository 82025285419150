.header {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #161d27;
    // background: linear-gradient(50deg, #161d27 0%, #161d27 50%, #4F00CC 90%);
    padding: 6px 0;
    // border-bottom: 2px solid #861be3;
    border-bottom: 2px solid #5B21CC;
    z-index: 100;

    transition: 0.3s;

    .__in {
        max-width: 1100px;
        padding: 0 14px;
        box-sizing: border-box;

        margin: 0 auto;
        position: relative;

        display: flex;
        justify-content: space-between;

        .logo {
            width: 60px;
        }

        .menu {
            display: flex;

            align-items: center;

            padding-left: 20px;

            overflow-x: scroll;

            display: flex;
            position: relative;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
                display: none;
                -ms-overflow-style: none;
                /* IE и Edge */
                scrollbar-width: none;
            }

            .li {
                cursor: pointer;
                white-space: nowrap;

                &:hover span {
                    border-bottom: 1px solid #fff;
                }

                &:active {
                    transform: scale(0.94);
                    transition: transform 0.2s;
                }

                color: white;
                font-size: 18px;
                font-weight: 700;

                padding: 12px 24px;
            }
        }
    }
}

.before {
    width: 100%;
    height: 78px;
}

html {
    scroll-behavior: smooth
}