.slider {
    width: 100%;
    // background-color: #161d27;

    background: url('/widgets/slider/icons/snippet.png') #161d27;

    /* Путь к картинке */
    background-position: 0 0;
    background-repeat: repeat;
    animation: animated-sky 60s linear infinite;

    .__in {

        color: white;

        width: 100%;
        padding: 44px 0px;
        box-sizing: border-box;

        margin: 0 auto;
        position: relative;

        .title {
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            font-weight: 700;
            font-size: 48px;
            line-height: 100%;

            padding: 0 14px;
            box-sizing: border-box;
            padding-bottom: 34px;
        }

        .box {
            width: 360px;
            height: 380px;

            border-radius: 24px;
            background-color: #ebecf4;

            display: flex;
            flex-direction: column;

            padding: 24px;
            box-sizing: border-box;

            .icon {
                
            }
            .name {
                // color: #861be3;
                // color: #5B21CC;
                color: #703cd3;
                font-size: 24px;
                font-weight: 700;
                padding: 12px 0;

            }
            .description {
                color: #516071;
                font-size: 14x;
                font-weight: 700;
            }
        }

    }
}


@keyframes animated-sky {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 8000px;
    }
}