.process {
    width: 100%;
    background-color: #ebecf4;

    // border-bottom: 4px dotted #861be3;

    .__in {

        width: 100%;
        max-width: 1100px;
        padding: 44px 14px;
        box-sizing: border-box;

        margin: 0 auto;
        position: relative;

        display: flex;
        align-items: center;
        flex-wrap: wrap;

        justify-content: space-between;


        .title {
            color: #181818;
            font-size: 56px;
            line-height: 90%;
            font-weight: 800;
            max-width: 770px;

            margin-bottom: 34px;

            @media screen and (max-width: 500px) {
                & {
                    font-size: 46px;
                }
            }
        }

        .sub_box {

            display: flex;
            justify-content: space-between;
            align-items: baseline;
            flex-wrap: wrap;

            padding: 0px 0;

            .sub_title {
                flex: 1;
                max-width: 90%;
                color: #181818;
                font-size: 28px;
                line-height: 1.1;
                font-weight: 400;
                max-width: 90%;
                grid-area: lead;
            }

            .sub_list {
                flex: 1;
                min-width: 300px;
                box-sizing: border-box;
                max-width: 400px;

                @media screen and (max-width: 500px) {
                    & {
                        margin-top: 24px;
                    }
                }


                .line {
                    display: flex;
                    align-items: center;
                    padding: 8px 0;
                    color: #516071;

                    .before {
                        width: 10px;
                        height: 10px;
                        min-width: 10px;
                        min-height: 10px;
                        display: block;
                        border-radius: 2px;
                        // background-color: #861be3;
                        background-color: #5B21CC;
                    }

                    .name {
                        color: #181818;
                        font-size: 20px;
                        line-height: 1.2;
                        font-weight: 400;
                        opacity: 0.7;
                        padding-left: 12px;
                    }
                }
            }
        }

        .list {
            // display: flex;
            // flex-wrap: wrap;

            // justify-content: space-between;

            margin-top: 34px;

            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            // margin-bottom: 34px;

            @media screen and (max-width: 959px) {
                & {
                    grid-template-columns: 1fr 1fr;
                }
            }

            @media screen and (max-width: 500px) {
                & {
                    grid-template-columns: 1fr;
                }
            }

            .li {
                border-width: 2px;
                border-radius: 5px;
                border-style: solid;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 16px 40px 40px 16px;

                &:last-child {
                    margin-right: 0;
                }

                .tag {
                    margin-bottom: 16px;
                    font-size: 26px;
                    line-height: 1;
                    font-weight: 600;
                }

                .name {
                    width: fit-content;
                    padding: 8px 12px;
                    color: #ffffff;
                    font-size: 20px;
                    line-height: 1;
                    font-weight: 400;
                    border-radius: 3px;
                    margin-bottom: 18px;
                }

                &.p1, &.p2, &.p3 {
                    border-color: #a2c1c4;
                    .tag {
                        color: #6d8a8d;
                    }
                    .name {
                        background: #a2c1c4;
                    }
                }

                // &.p2 {
                //     border-color: #c1ee3a;
                //     .tag {
                //         color: #b9d468;
                //     }
                //     .name {
                //         background: #c1ee3a;
                //     }
                // }

                // &.p4 {
                //     border-color: #d69aa7;
                //     .tag {
                //         color: #b96577;
                //     }
                //     .name {
                //         background: #d69aa7;
                //     }
                // }

                .icon {
                    margin-bottom: 16px;
                }

                .text {
                    color: #181818;
                    font-size: 18px;
                    line-height: 1.2;
                    font-weight: 400;
                }
            }
        }

    }

}