.main {
    width: 100%;
    background-color: #ebecf4;
    // border-bottom: 4px dotted #861be3;
    border-bottom: 4px dotted #5B21CC;

    .__in {
        width: 100%;
        padding: 44px 14px;
        @media screen and (max-width: 500px) {
            & {
                padding: 24px 14px 34px 14px;
            }
        }
        max-width: 1100px;
        box-sizing: border-box;

        margin: 0px auto;
        position: relative;

        display: flex;
        flex-wrap: wrap;
        align-items: center;

        justify-content: space-between;

        .info {
            flex: 1;

            .head {
                font-size: 94px;
                font-weight: 700;
                color: #161d27;
                line-height: 94px;
                margin-bottom: 16px;
                @media screen and (max-width: 500px) {
                    & {
                        font-size: 84px;
                    }
                }
            }

            .sub_head {
                color: #516071;
                font-weight: 700;
                font-size: 32px;
                line-height: 100%;
            }

            .button_box {
                display: flex;
                margin: 24px 0;

                .button {
                    font-weight: 700;
                    font-size: 18px;
                    color: #fff;
                    padding: 16px 58px;
                    // background-color: #5bddb2;
                    background-color: #8489fb;
                    border-radius: 10px;
                    transition: background-color .3s linear, color .3s linear;

                    &:active {
                        transform: scale(0.94);
                        transition: transform 0.2s;
                    }
                    &:hover {
                        // background-color: #4bc59c;
                        background-color: #6b71e2;
                        transition: transform 0.2s;
                    }
                }
            }

            .list {
                margin-bottom: 18px;

                .li {
                    display: flex;
                    align-items: center;
                    padding: 8px 0;
                    padding-left: 4px;
                    color: #516071;

                    .before {
                        width: 8px;
                        height: 8px;
                        border-radius: 2px;
                        // background-color: #861be3;
                        background-color: #5B21CC;
                    }

                    .name {
                        padding-left: 16px;
                        font-weight: 700;
                        font-size: 18px;
                    }
                }
            }

        }

        .icon {
            // padding-left: 16px;

            max-width: 100%;

            .box {
                display: flex;
                justify-content: center;

                .brd1 {
                    padding: 6px;
                    width: 400px;
                    max-width: 100%;
                    box-sizing: border-box;
                    // background-color: #861be36e;
                    background-color: #3633ef63;
                    border-radius: 10% 30% 10% 30%;

                    .brd2 {
                        border-radius: 10% 30% 10% 30%;
                        overflow: hidden;
                        // border: 4px solid #861be381;
                        border: 6px solid #3633ef8a;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

}