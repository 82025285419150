.feedback {
    width: 100%;
    background-color: #ebecf4;

    .__in {

        width: 100%;
        max-width: 1100px;

        margin: 0 auto;
        padding: 0 14px;
        padding-top: 14px;
        padding-bottom: 44px;
        box-sizing: border-box;

        .padding {
            position: relative;

            padding: 64px 44px;
            box-sizing: border-box;

            border-radius: 6px;

            background-color: #161d27;

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .left {
                flex: 2;
                font-size: 32px;
                line-height: 34px;
                color: white;
                font-weight: 400;
                letter-spacing: 2px;

                padding-bottom: 28px;
                
                .text {
                    max-width: 450px;
                }
            }

            .form {

                flex: 1;

                .sendForm {
                    width: 100%;
                    padding-bottom: 28px;
                    color: #92ff92;
                    font-size: 28px;
                    font-weight: 500;
                }

                .form_layout {

                    width: 100%;

                    span {
                        padding-left: 4px;
                    }

                    .error {
                        color: #f35353;
                        font-weight: 500;
                    }

                    .success {
                        color: #4bb44b;
                        font-weight: 500;
                    }

                    .input_name {

                        padding-bottom: 10px;

                        .top {
                            color: white;
                            font-weight: 500;
                            padding-bottom: 2px;
                        }

                        input {
                            width: 100%;

                            padding: 0px;
                            margin: 0px;
                            display: block;

                            height: 48px;
                            font-size: 20px;
                            letter-spacing: 1px;
                            font-weight: 400;

                            border-radius: 6px;
                            box-sizing: border-box;
                            border: 2px solid #ccc8bf;


                            padding: 0 8px;

                            font-weight: 500;

                            letter-spacing: 1px;

                            &::placeholder {
                                color: gray;
                                font-size: 18px;
                            }

                            &:focus {
                                outline: none;
                                border: 2px solid #ccc8bf;
                            }
                        }

                        .bottom {
                            font-size: 15px;
                        }

                    }


                }


                .buttonLayout {
                    .button {
                        cursor: pointer;

                        padding: 12px 18px;
                        border-radius: 12px;
                        // background-color: #685a83;
                        // border: 2px solid #564971;

                        text-align: center;

                        // color: #212121;
                        // background-color: #44eb99;
                        background-color: #8489fb;

                        &:hover {
                            // background-color: #4bc59c;
                            background-color: #6b71e2;
                            transition: transform 0.2s;
                        }

                        .text {
                            font-weight: 500;
                            font-size: 18px;
                            color: #000;
                        }
                    }
                }

            }
        }
    }

}