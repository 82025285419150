.typeTraining {

    .__content {

        display: flex;
        justify-content: center;

        // margin: 24px;

        .group {
            margin-right: -12px;
            margin-bottom: -12px;

            position: relative;

            display: flex;
            flex-wrap: wrap;

            @media screen and (max-width: 500px) {
                & {
                    margin-right: 0px;
                }
            }

            &>div {
                flex: 1;

                .li {
                    cursor: pointer;

                    display: flex;
                    padding: 34px 24px;
                    padding-bottom: 40px;
                    padding-right: calc(42px + 94px);
                    // padding: calc(24px + 18px);
                    border-radius: 24px 12px 24px 12px;

                    font-weight: 500;

                    background: #F5E6FE;

                    margin-bottom: 12px;
                    margin-right: 12px;

                    position: relative;
                    overflow: hidden;
                    
                    @media screen and (max-width: 500px) {
                        & {
                            width: 100%;
                            box-sizing: border-box;
                            margin-right: 0px;
                        }
                    }

                    .name {
                        width: 100%;
                        font-size: 22px;


                        &>div {
                            padding: 1px 0;

                            span {
                                font-size: 18px;

                                margin-left: 20%;
                                color: #000;
                                background-color: blue;
                                border-radius: 6px 4px 6px 4px;
                                padding: 1px 12px;
                                white-space: nowrap;
                            }
                        }
                    }

                    // width: min-content;
                    .price {
                        position: absolute;
                        right: 0px;
                        bottom: 0px;
                        background-color: #bac5ed;
                        border-radius: 50% 0px 0px 0px;
                        padding: 6px;
                        font-size: 20px;
                    }

                    &.t1 {
                        span {
                            background-color: #9df5c1 !important;
                        }
                    }

                    &.t2 {
                        span {
                            background-color: #fdc2c2 !important;
                        }
                    }

                    &.t3 {
                        span {
                            background-color: #b2b2ff !important;
                        }
                    }
                }
            }

            .offline {}

            .online {}


        }
    }

}