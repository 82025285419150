.close {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 10px;

    cursor: pointer;
    z-index: 10000;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {}

    div {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -5px;
        margin-left: -5px;
        width: 10px;
        height: 10px;
        box-shadow: 0 0 60px #000;
        z-index: 1000;
    }
}

.popout {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;

    z-index: 3001;

    overflow-y: auto;



    .__size {
        width: 100%;
        min-height: 100%;
        max-width: 900px;

        margin: 0 auto;

        box-sizing: border-box;
        animation: on_popup 0.5s alternate;

        display: flex;
        justify-content: center;

        display: flex;
        justify-content: center;
        align-items: center;

        .main {
            width: 100%;
            max-width: 900px;
            padding: 20px;
            box-sizing: border-box;
        }

    }

    .__full {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }


}


@keyframes on_popup {
    0% {
        transform: scale(0);
    }

    80% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}