.cardsScroll {

    width: 100%;


    .cardsScroll__box {
        position: absolute;
        height: 0px;
        background-color: aqua;
        width: 100%;
        max-width: 1100px;
    }

    .cardsScroll__in {
        width: 100%;
        overflow-x: scroll;
    
        display: flex;
        position: relative;
    
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            display: none;
            -ms-overflow-style: none;
            /* IE и Edge */
            scrollbar-width: none;
        }
        .cardsScroll__content {
            display: flex;
            transform: 0.2s;

            position: relative;
            z-index: 1;
        }
    }

    .arrows {
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        padding: 0 14px;
        box-sizing: border-box;
        padding-top: 34px;

        .__in {
            display: flex;
            div {
                cursor: pointer;

                &:active {
                    transform: scale(0.94);
                    transition: transform 0.2s;
                }     

                width: 48px;
                height: 48px;
                border-radius: 12px;
                // background-color: #5bddb2;
                background-color: #8489fb;
                
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .left {
                margin-right: 10px;
            }

            .right {}
        }
    }

    // .dirIcon {
    //     width: 40px;
    //     height: 40px;
    //     border-radius: 50%;
    //     position: absolute;
    //     background-color: #00000017;

    //     &.touch-start {
    //         transition: transform 0.2s ease;
    //         transform: scale(0.95);
    //     }

    //     &.directionLeft {
    //         top: calc(74px - 20px);
    //         left: 10px;
    //     }

    //     &.directionRight {
    //         top: calc(74px - 20px);
    //         right: 10px;
    //     }
    // }
}