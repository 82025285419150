.footer {
    width: 100%;
    background-color: #161d27;
    // border-top: 2px solid #861be3;
    border-top: 2px solid #5B21CC;

    .__in {

        width: 100%;
        max-width: 1100px;
        padding: 44px 14px;
        box-sizing: border-box;

        margin: 0 auto;
        position: relative;

        .title {
            color: #fff;
            font-size: 46px;
            line-height: 90%;
            font-weight: 800;
        }

        .main {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .contacts {

                display: flex;
                flex-wrap: wrap;
                flex: 1;

            }

            .box {
                padding-right: 44px;
                margin-top: 28px;

                .sub_title {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 100%;
                    color: #7a3df3;
                    margin: 0 0 16px;
                }

                .list {
                    display: flex;
                    flex-direction: column;

                    .li {
                        font-weight: 400;
                        font-size: 15px;
                        color: #fff;
                        display: block;
                        transition: color .3s linear;
                        padding: 6px 0;

                        &.insta {
                            font-size: 18px;
                            line-height: 16px;
                        }

                        .instagram {
                            display: flex;
                            align-items: center;

                            .icon {
                                padding-right: 8px;
                            }
                        }
                    }
                }

                &.tg {
                    .list .li {
                        cursor: pointer;
                        white-space: nowrap;

                        &:hover span {
                            border-bottom: 1px solid #fff;
                        }
                    }
                }

                &.presents {

                }

            }

            .logo {

                // display: flex;
                // flex-direction: column;

                display: none;

                padding-top: 34px;


                .name {
                    font-size: 18px;
                    line-height: 18px;
                    font-weight: 800;
                    color: #e7eaee;
                    border: 2px solid #9c32f8a9;
                    background-color: #9c32f865;
                    padding: 8px;
                    border-radius: 6px;

                    span {
                        letter-spacing: 4px;
                    }
                }

            }


        }

    }

}